@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.analysis {
  position: relative;
  background-image: url("../src/Assets/login-page-image.png");
  background-size: cover;
  background-position: center center;
  padding: 0;
  border-radius: 1.2rem;
  z-index: -2;
}

.analysis::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #3266fc;
  opacity: 0.6; /* Adjust the opacity to make it slightly visible */
  border-radius: 1.2rem;
  z-index: -1; /* Move the pseudo-element behind the content */
}

/* Your existing styles */
.analysis > Box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure child elements are positioned relative to .analysis */
  z-index: 1; /* Ensure the child elements are above the pseudo-element */
}

.intro {
  position: relative;
  background-image: url("../src/Assets/login-page-image.png");
  background-size: cover;
  background-position: center center;
  border-radius: 1rem;
  z-index: 999;
}

.intro::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 1rem;
  background-color: #2970ff;
  opacity: 0.58; /* Adjust the opacity to make it slightly visible */
  z-index: 1000; /* Move the pseudo-element behind the content */
}

.intro > Box {
  display: flex;
  position: relative; /* Ensure child elements are positioned relative to .analysis */
  border-radius: 1rem;
  z-index: 1005; /* Ensure the child elements are above the pseudo-element */
}

/* Scrollbar styles for both vertical and horizontal scrollbars */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  height: 3px;
  border: 1px solid #e6edff;
}

::-webkit-scrollbar-thumb {
  background: #b5caff;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(181, 202, 255, 0.7);
}

.datagrid-container {
  max-width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling for small screens */
}

.datagrid-container-batch {
  min-width: 82vw;
  max-width: 82vw;
}

@media screen and (min-width: 601px) {
  .datagrid-container {
    overflow-x: hidden; /* Disable horizontal scrolling for larger screens */
  }
}

.custom-datagrid {
  border: solid 1px rgba(0, 0, 0, 0.3);
}

.custom-datagrid .MuiDataGrid-cell,
.custom-datagrid .MuiDataGrid-columnHeaders,
.custom-datagrid .MuiDataGrid-footerContainer,
.custom-datagrid .MuiDataGrid-columnHeader {
  border: solid 1px rgba(0, 0, 0, 0.07);
}

.custom-datagrid .MuiDataGrid-toolbarContainer .MuiButton-text {
  color: black !important;
}

.datagrid-header-color {
  background-color: #dbe4ff;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
}

.row-color {
  background-color: #f0f4ff;
}

.datagrid-exam-container-empty {
  min-width: 80vw;
  max-width: 80vw;
  overflow-x: auto;
}

.datagrid-exam-container {
  max-width: 100%;
  overflow-x: auto;
}

.datagrid-batch-container {
  max-width: 100%;
  overflow-x: auto;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none !important;
}

@keyframes moveLeftToRight {
  0% {
    left: 0; /* Start from the left */
  }
  100% {
    left: 100%; /* Move to the right */
  }
}

@keyframes moveLeft {
  0% {
    object-position: 0% 0%;
  }
  100% {
    object-position: 100% 0%;
  }
}

@keyframes moveRight {
  0% {
    object-position: 100% 0%;
  }
  50% {
    object-position: 0% 0%;
  }
  100% {
    object-position: 100% 0%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
